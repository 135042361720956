<template>
  <v-container>
    <v-form ref="form" v-model="valid" @submit.prevent="save">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            autofocus
            maxlength="80"
            v-model="product.description"
            :rules="[rules.required]"
            label="Descrição"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <div v-if="$vuetify.breakpoint.mobile">
        <v-btn :disabled="!valid" color="success" class="mr-4" @click="save">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
        <v-btn color="warning" class="mr-4" @click="reset"
          ><v-icon>mdi-broom</v-icon></v-btn
        >
        <v-btn color="error" to="/produtos"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <div v-if="!$vuetify.breakpoint.mobile">
        <v-btn :disabled="!valid" color="success" class="mr-4" @click="save"
          >Salvar</v-btn
        >
        <v-btn color="warning" class="mr-4" @click="reset">Limpar</v-btn>
        <v-btn color="error" to="/produtos">Cancelar</v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/global";
export default {
  name: "ProductForm",
  data: () => ({
    valid: false,
    product: {
      description: "",
    },
    rules: {
      required: (value) => !!value || "Obrigatorio.",
      min: (v) => (v && v.length >= 6) || "Minimo 6 caracteres!",
    },
  }),
  methods: {
    async save() {
      await this.$refs.form.validate();
      if (!this.valid) return;

      const method = this.product.id ? "put" : "post";
      const url = this.product.id
        ? `${baseApiUrl}/product/${this.product.id}`
        : `${baseApiUrl}/products`;
      axios[method](url, this.product)
        .then(() => {
          this.$router.push("/produtos");
        })
        .catch((error) => {
          if (error.response) {
            alert(error.response.data);
          }
        });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  mounted() {
    if (this.$route.params.id) {
      const url = `${baseApiUrl}/product/${this.$route.params.id}`;
      axios.get(url).then((res) => {
        this.product = res.data;
      });
    }
  },
};
</script>

<style>
.input-uppercase input {
  text-transform: uppercase;
}
</style>